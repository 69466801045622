<template style="margin-bottom: 160px; margin-top: 10px;">
  <div>
    <vx-card :title="card.title + (card.sub_title ? ' | ' : '') + 'Serial Number ' + card.sub_title"
      style="margin-bottom: 160px; margin-top: 10px;">
      <vs-tabs :color="colorx" v-model="tabs">
        <!-- <vs-tab @click="card.sub_title = 'Registration'" label="SN Registration">
          <div class="con-tab-ejemplo">
            <sn_register @initLength="initLength" />
          </div>
        </vs-tab> -->
        <!-- <vs-tab @click="card.sub_title = 'Allocation'" label="SN Allocation">
          <div class="con-tab-ejemplo">
            <sn_allocation :taxNumberLength="taxNumberLength" />
          </div>
        </vs-tab> -->
        <vs-tab @click="card.sub_title = 'Generation'" label="Tax Generation">
          <div class="con-tab-ejemplo">
            <sn_generate @initLength="initLength" />
          </div>
        </vs-tab>
        <vs-tab @click="card.sub_title = 'Export CSV'" label="Export CSV">
          <div class="con-tab-ejemplo">
            <sn_export @initLength="initLength" />
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>

//old version
// import sn_register from "./sn_register.vue";
// import sn_allocation from "./sn_allocation.vue";

// import sn_register from "./sn_register2.vue";
// import sn_allocation from "./sn_allocation2.vue";
import sn_generate from "./generate";
import sn_export from "./export/index.vue";
// import sn_export from "./export.vue";

export default {
  components: {
    // sn_register, sn_allocation,
    sn_generate, sn_export,
  },

  data() {
    return {
      card: {
        title: "Tax Invoice",
        sub_title: "Registration"
      },
      colorx: "success",
      tabs: 0,
      taxNumberLength: 0,
    };
  },
  watch: {},
  mounted() {
  },
  methods: {
    initLength(length) {
      this.taxNumberLength = length
    }
  },
};
</script>
